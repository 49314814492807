import logo from '../../assets/logo.svg';
import homeImg from "../../assets/home-img.png";
import appleIcon from "../../assets/apple-icon.svg";
import googleIcon from "../../assets/goole-icon.svg";
import testFlightIcon from "../../assets/testFlight-icon.svg";
import androidIcon from "../../assets/android-icon.svg";
import { useEffect, useState } from 'react';
import { useNavigate } from "react-router-dom";
import './index.css';
import {useTranslation} from "react-i18next";

function Home() {
    const navigate = useNavigate();
    const [appMap, setAppMap] = useState(new Map());
    const baseUrl = "https://www.ubitex.com";

  const { t } = useTranslation();

  useEffect(() => {
        getDownload();
    }, []);

    const getDownload = async () => {
        try {
            const res = await fetch(baseUrl + "/v1/app/public/app-info/versions");
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await res.json();
            console.log(data);
            const map = new Map();
            data.data.forEach(item => {
                map.set(item.client, item.url)
            });
            setAppMap(map);

        } catch (err) {
            console.error('There has been a problem with your fetch operation:', err);
        }

    }
  const app_list = [
    {
      icon: appleIcon,
      label: "App Store",
      client: "ios"
    },
    {
      icon: googleIcon,
      label: "Google Play",
      client: "android-google-play"
    },
    {
      icon: testFlightIcon,
      label: "TestFlight",
      url: "/testflight"
    },
    {
      icon: androidIcon,
      label: "Android APK",
      client: "android"
    }
  ]

  const toDownload = (item) => {
    if (item.url) {
        navigate(item.url);
    } else {
        window.open(appMap.get(item.client),"_self");
    }
  }
  return (
    <div className="home">
      <header className="home-header">
        <img src={logo} className="home-logo" alt="logo" />
         <p>{t('subtitle')}</p>
      </header>
      <div className="content">
        <img src={homeImg} className="main-img" alt=""/>
      </div>
      <div className="home-list">
        {app_list.slice(0, 2).map((item, index) => <div className="home-item" key={index} onClick={() => toDownload(item)}>
          <img src={item.icon} alt=""/>
          <span>{item.label}</span>
        </div>)}
      </div>
      {/* <div>应用商店不支持你所在国家请尝试以下方式下载</div> */}
      <div className="home-list">
        {app_list.slice(2, 4).map((item, index) => <div className="home-item" key={index} onClick={() => toDownload(item)}>
          <img src={item.icon} alt=""/>
          <span>{item.label}</span>
        </div>)}
      </div>
    </div>
  );
}

export default Home;
