import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';


import zhCN from './l10n/zh-CN.json';
import zhTW from './l10n/zh-TW.json';
import en from './l10n/en.json';
import fr from './l10n/fr.json';
import de from './l10n/de.json';
import ru from './l10n/ru.json';
import id from './l10n/id.json';
import ko from './l10n/ko.json';
import ja from './l10n/ja.json';
import vi from './l10n/vi.json';
import tr from './l10n/tr.json';

const languageResources = {
  zh: { translation: zhCN },
  'zh-TW': { translation: zhTW },
  'zh-HK': { translation: zhTW },
  en: { translation: en },
  fr: { translation: fr },
  de: { translation: de },
  ru: { translation: ru },
  id: { translation: id },
  ko: { translation: ko },
  ja: { translation: ja },
  vi: { translation: vi },
  tr: { translation: tr },
}


function getDefaultLanguage(){
  const browserLanguage = navigator.language || navigator.userLanguage;

  console.log(browserLanguage);
  if(Reflect.has(languageResources,browserLanguage)){
    return browserLanguage;
  }
  return browserLanguage.split('-')[0] || 'en';
}

console.log('getDefaultLanguage',getDefaultLanguage())

i18n
  .use(initReactI18next)
  .init({
    resources: languageResources,
    lng: getDefaultLanguage() , // 设置默认语言为浏览器语言
    fallbackLng: 'en', // 回退语言
    interpolation: {
      escapeValue: false, // React 已经安全处理
    },
    // 可选配置
    debug: true, // 开启调试模式，开发时使用
  });

export default i18n;
