import "./index.css";
import TestFlightBg from "../../assets/testFlight-bg.png";
import ArrowIcon from "../../assets/arrow-icon.svg";
import testFlightIcon from "../../assets/testFlight-icon.svg";
import { useEffect, useState } from "react";
import {useTranslation} from "react-i18next";

function TestFlight () {
    const [showDrawer, setShowDrawer] = useState(true);
    const baseUrl = "https://www.ubitex.com";
    const [testFlightMap, setTestFlightMap] = useState(new Map());

    const { t } = useTranslation();

    useEffect(() => {
        getDownload();
        document.body.style.overflow = 'hidden'
    }, []);

    const getDownload = async () => {
        try {
            const res = await fetch(baseUrl + "/v1/app/public/app-info/versions");
            if (!res.ok) {
                throw new Error('Network response was not ok');
            }
            const data = await res.json();
            console.log(data);
            const map = new Map();
            data.data.forEach(item => {
                map.set(item.client, item.url)
            });
            setTestFlightMap(map);

        } catch (err) {
            console.error('There has been a problem with your fetch operation:', err);
        }

    }

    const toDownLoad = (client) => {
        window.open(testFlightMap.get(client),"_self");
    }

    return <div className="testflight-container">
        <img src={TestFlightBg} alt="" className="bg-head" onClick={() => setShowDrawer(!showDrawer)}/>
        <div className="text-head">
            <span>{t('welcome_message')}</span><br/>
            <span>{t('enter_crypto_world')}</span>
        </div>
        <div className="mini-text">
            <span>{t('download_now')}</span>
            <img src={ArrowIcon} alt="" className="arrow-icon"/>
        </div>
        {/* <div className="filter"></div> */}
        <div className="drawer drawer-show">
            <div className="drawer-content">
                <div className="flex-alc">
                    <div className="line"></div>
                    <span className="title">{t('step_one')}</span>
                </div>
                <div className="download-text">{t('install_testflight')}</div>
                <div className="button-style flex-alc" onClick={() => {
                    window.open("https://apps.apple.com/us/app/testflight/id899247664","_self")
                }}>
                    <img src={testFlightIcon} alt=""/>
                    <span>TestFlight</span>
                </div>
                <div className="flex-alc" style={{marginTop: "38px"}}>
                    <div className="line"></div>
                    <span className="title">{t('step_two')}</span>
                </div>
                <div className="download-text">{t('view_ubiex_in_testflight')}</div>
                <div className="download-text-sub">{t('click_install_button')}</div>
                {testFlightMap.has("ios-test-flight") && <div className="button-style button-style-big" onClick={() => toDownLoad("ios-test-flight")}>{t('line')}1</div>}
                {testFlightMap.has("ios-test-flight-line2") && <div className="button-style button-style-big" onClick={() => toDownLoad("ios-test-flight-line2")}>{t('line')}2</div>}
            </div>

        </div>
    </div>
}

export default TestFlight;
