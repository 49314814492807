import Home from "./pages/home";
import TestFlight from "./pages//testflight";
import { HashRouter as Router, Route, Routes, Link } from 'react-router-dom';
// import './App.css';
import './l10n';

function App() {
  return (
    <Router>
       <Routes>
          <Route path="/" element={<Home />} />
          <Route path="/testflight" element={<TestFlight />} />
        </Routes>
    </Router>
  );
}

export default App;
